<template>
  <div>
    <v-row class="mb-4" style="">
      <v-col cols="12" class="pa-0">
        <v-img
          src="@/assets/CE/pic3.jpg"
          class="pa-0"
          height="151"
          width="414"
          style="display: block; margin-left: auto; margin-right: auto;"
        />
      </v-col>
      <v-col cols="12" class="pb-0">
        <div class="text-center fn16 mx-4 mb-0" style="font-weight: 600;">
          Feline Atopic Syndrome (FAS) : อาการและแนวทางการวินิจฉัย
        </div>
      </v-col>
      <v-col cols="12" class="pb-0">
        <p class="text-center fn12 mx-8">
          รู้จักแนวทางการวินิจฉัยและอาการเบื้องต้นของโรค
          FASโรคกลุ่มภูมิแพ้ในแมวที่แตกต่างจากในสุนัข
        </p>
      </v-col>
      <v-col cols="6" class="text-right py-0">
        <span
          style="font-size: 11px; font-weight: 600; background: #DFE2E9; color: #6E798C;"
          class="rounded-lg pa-1"
        >
          9 Days Left
        </span>
      </v-col>
      <v-col cols="1" class="py-0">
        <img src="@/assets/icon/CE.png" width="15" height="15" />
      </v-col>
      <v-col cols="4" class="text-left py-0">
        <span style="font-size: 10px; font-weight: 600; color: #1A347F;">
          100 Points
        </span>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <div class="fn12 overflow-auto" style="height: 280px;">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
          pellentesque ridiculus at quisque nunc viverra. Faucibus porta
          consectetur vehicula massa habitasse. Nulla sit purus quam platea
          placerat. Pharetra mattis est integer viverra nam nibh. Scelerisque
          ante orci duis cras pellentesque ultrices lorem. Mi id massa tortor eu
          pharetra non. Erat gravida habitant morbi faucibus consequat
          consequat, in nibh. Consectetur ut at sapien sed luctus amet, massa
          est. Morbi dui semper enim euismod est. Lacus, ut ac duis quis viverra
          euismod leo diam eget. Eu non aliquet pellentesque et quam nunc
          blandit. Vitae neque cras suspendisse scelerisque lacus aliquet
          commodo elementum. Facilisis enim vel morbi bibendum blandit diam.
          Consequat, posuere nec dui platea cras ut risus iaculis id. Fermentum
          montes, auctor faucibus viverra. Arcu, varius eros, ac porta viverra
          nisl. Maecenas cursus amet malesuada consequat consequat. Sed nunc
          quis vestibulum vitae turpis non cursus. Dui dignissim viverra feugiat
          commodo, in consectetur cras a. Erat consequat viverra tincidunt eu,
          et, turpis convallis sodales in. Non etiam molestie enim pharetra
          praesent ac, consectetur elementum. Vel, nunc amet eget nec
          adipiscing. Integer libero, sagittis metus, fames dictum pellentesque
          consequat leo vulputate. Morbi quisque semper sit eu non eu. Est sit
          at penatibus ut eu turpis pulvinar nullam nunc. Neque, lobortis mi
          urna, scelerisque eu, vestibulum bibendum. Et in purus euismod tellus
          molestie suspendisse velit. Gravida elit vitae pulvinar parturient
          donec fringilla leo in ac. Faucibus pharetra purus mattis id at hac.
          Est at arcu egestas arcu arcu.
        </div>
      </v-col>
    </v-row>
    <v-btn
      block
      elevation="2"
      large
      outlined
      x-large
      class="font-weight-bold rounded-lg text-capitalize"
      style="color: white; background: linear-gradient(268.1deg, #07204B 7.54%, #1A347F 100.72%);"
      @click="goToTest"
    >
      Do the Test
    </v-btn>
  </div>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      logisticName: "",
      description: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },
      checkValidate: true,
      lazy: false,
      user: "",
      productOptionList: [],
    };
  },
  methods: {
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    goToTest() {
      this.$router.push("TestCE");
    },
    async addproductOptionList() {
      this.productOptionList.push({
        minWeight: this.minWeight,
        maxWeight: this.maxWeight,
        price: this.price,
      });
    },
    deleteproductOptionList() {
      if (this.productOptionList.length > 0) {
        this.productOptionList.pop();
      }
    },
    async addRow1() {
      this.productPriceList.push({
        price: null,
        // description: this.listDescription,
        companyId: this.companyId,
      });
    },
    deleteRow1() {
      if (this.productPriceList.length > 0) {
        this.productPriceList.pop();
      }
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("userBiData"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          name: this.logisticName,
          note: this.description,
          status: this.activeFlag,
          weightPrices: this.productOptionList,
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/shippings`,
          data,
          auth
        );
        console.log("createBrand", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างการปิดงาน`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageLogistic");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>
